/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import Modal from "./modal"
import Accordion from "./accordion"
import Container from "./container"
import { useStaticQuery, graphql } from "gatsby"
import ContentCard from "./content-card"
import Link from "./link"
import CTA from "./cta"
import propaneLogo from "../images/propane-health-logo.svg"
import AnimateChildren from "./animate-children"
const Header = ({ showContact }) => {
  return (
    <header
      sx={{
        width: "100%",
        height: "160px",
        mb: "-160px",
        position: "relative",
        zIndex: 1,
      }}
    >
      <Container
        sx={{
          height: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <AnimateChildren
          visibleStyle={{ opacity: 1 }}
          invisibleStyle={{ opacity: 0 }}
          transition={{ duration: 0.9 }}
          sx={{
            position: "relative",
          }}
          triggerOnce={true}
          delay={0}
          initialDelay={0.5}
          containerStyle={{}}
        >
          <Link
            link="/"
            sx={{
              mr: "20px",
            }}
          >
            <img
              src={propaneLogo}
              sx={{
                width: "auto",
                height: ["40px", "40px", "50px", "50px", "60px"],
              }}
              alt="Propane.health"
            />
          </Link>
        </AnimateChildren>
        <AnimateChildren
          visibleStyle={{ opacity: 1 }}
          invisibleStyle={{ opacity: 0 }}
          transition={{ duration: 0.9 }}
          sx={{
            position: "relative",
          }}
          triggerOnce={true}
          delay={0}
          initialDelay={0.5}
          containerStyle={{}}
        >
          <CTA onClick={showContact}>Contact us</CTA>
        </AnimateChildren>
      </Container>
    </header>
  )
}

export default Header
