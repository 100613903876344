import React from "react"
import { Global, css } from "@emotion/core"

import SourceCodeProBlack from "../fonts/SourceCodeProBlack.woff2"
import SourceCodeProBlackIt from "../fonts/SourceCodeProBlackIt.woff2"
import SourceCodeProBoldIt from "../fonts/SourceCodeProBoldIt.woff2"
import SourceCodeProBold from "../fonts/SourceCodeProBold.woff2"
import SourceCodeProExtraLight from "../fonts/SourceCodeProExtraLight.woff2"
import SourceCodeProExtraLightIt from "../fonts/SourceCodeProExtraLightIt.woff2"
import SourceCodeProLight from "../fonts/SourceCodeProLight.woff2"
import SourceCodeProLightIt from "../fonts/SourceCodeProLightIt.woff2"
import SourceCodeProMediumIt from "../fonts/SourceCodeProMediumIt.woff2"
import SourceCodeProRegular from "../fonts/SourceCodeProRegular.woff2"

const Fonts = () => (
  <Global
    styles={css`
      @font-face {
        font-family: "Source Code Pro";
        src: url(${SourceCodeProExtraLightIt}) format("woff2");
        font-weight: 200;
        font-style: italic;
        font-display: swap;
      }

      @font-face {
        font-family: "Source Code Pro";
        src: url(${SourceCodeProBlackIt}) format("woff2");
        font-weight: 900;
        font-style: italic;
        font-display: swap;
      }

      @font-face {
        font-family: "Source Code Pro";
        src: url(${SourceCodeProBoldIt}) format("woff2");
        font-weight: bold;
        font-style: italic;
        font-display: swap;
      }

      @font-face {
        font-family: "Source Code Pro";
        src: url(${SourceCodeProBlack}) format("woff2");
        font-weight: 900;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: "Source Code Pro";
        src: url(${SourceCodeProBold}) format("woff2");
        font-weight: 600;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: "Source Code Pro";
        src: url(${SourceCodeProMediumIt}) format("woff2");
        font-weight: normal;
        font-style: italic;
        font-display: swap;
      }

      @font-face {
        font-family: "Source Code Pro";
        src: url(${SourceCodeProMediumIt}) format("woff2");
        font-weight: 500;
        font-style: italic;
        font-display: swap;
      }

      @font-face {
        font-family: "Source Code Pro";
        src: url(${SourceCodeProLightIt}) format("woff2");
        font-weight: 300;
        font-style: italic;
        font-display: swap;
      }

      @font-face {
        font-family: "Source Code Pro";
        src: url(${SourceCodeProLight}) format("woff2");
        font-weight: 300;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: "Source Code Pro";
        src: url(${SourceCodeProRegular}) format("woff2");
        font-weight: normal;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: "Source Code Pro";
        src: url(${SourceCodeProMediumIt}) format("woff2");
        font-weight: 600;
        font-style: italic;
        font-display: swap;
      }

      @font-face {
        font-family: "Source Code Pro";
        src: url(${SourceCodeProExtraLight}) format("woff2");
        font-weight: 200;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: "Source Code Pro";
        src: url(${SourceCodeProRegular}) format("woff2");
        font-weight: 500;
        font-style: normal;
        font-display: swap;
      }
      @import url("https://p.typekit.net/p.css?s=1&k=wvl3klx&ht=tk&f=19114.19121.19123&a=7871940&app=typekit&e=css");

      @font-face {
        font-family: "akzidenz-grotesk";
        src: url("https://use.typekit.net/af/8dd729/00000000000000000001362c/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3")
            format("woff2"),
          url("https://use.typekit.net/af/8dd729/00000000000000000001362c/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3")
            format("woff"),
          url("https://use.typekit.net/af/8dd729/00000000000000000001362c/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3")
            format("opentype");
        font-display: auto;
        font-style: normal;
        font-weight: 700;
      }

      @font-face {
        font-family: "akzidenz-grotesk";
        src: url("https://use.typekit.net/af/9d033e/000000000000000000013633/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n5&v=3")
            format("woff2"),
          url("https://use.typekit.net/af/9d033e/000000000000000000013633/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n5&v=3")
            format("woff"),
          url("https://use.typekit.net/af/9d033e/000000000000000000013633/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n5&v=3")
            format("opentype");
        font-display: auto;
        font-style: normal;
        font-weight: 500;
      }

      @font-face {
        font-family: "akzidenz-grotesk";
        src: url("https://use.typekit.net/af/5556ca/000000000000000000013635/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3")
            format("woff2"),
          url("https://use.typekit.net/af/5556ca/000000000000000000013635/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3")
            format("woff"),
          url("https://use.typekit.net/af/5556ca/000000000000000000013635/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3")
            format("opentype");
        font-display: auto;
        font-style: normal;
        font-weight: 400;
      }

      @font-face {
        font-family: "akzidenz-grotesk";
        src: url(${SourceCodeProRegular}) format("opentype");
        font-weight: 200;
        font-style: normal;
        font-display: swap;
      }
    `}
  />
)

export default Fonts
