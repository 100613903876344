/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { motion } from "framer-motion"
import { useIntersection } from "react-use"
import { useInView } from "react-intersection-observer"

function AnimateChildren({
  children,
  visibleStyle,
  invisibleStyle,
  containerStyle,
  initialDelay,
  delay,
  triggerOnce = true,
  transition,
  threshold = 0.5,
  rootMargin,
  ...restProps
}) {
  const { ref, inView } = useInView({
    threshold,
    initialInView: false,
    triggerOnce,
    ...(rootMargin && { rootMargin }),
  })
  return (
    <div ref={ref} sx={containerStyle}>
      {(Array.isArray(children) ? children : [children]).map((child, index) => (
        <motion.div
          initial={invisibleStyle}
          key={index}
          variants={{
            visible: visibleStyle,
            invisible: invisibleStyle,
          }}
          animate={inView ? "visible" : "invisible"}
          transition={{
            delay: index * (delay || 0) + (initialDelay || 0),
            ...transition,
          }}
          {...restProps}
        >
          {child}
        </motion.div>
      ))}
    </div>
  )
}
export default AnimateChildren
