/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import Link from "./link"

const CTA = ({ link, classes, ...restProps }) => {
  const style = {
    border: "2px solid #D4576E",
    transition: "0.4s",
    ":hover": {
      border: "2px solid #ebebeb",
      color: "#D4576E",
      backgroundColor: "#ffff",
      boxShadow: "0px 0px 40px rgba(212, 87, 110, 0.2)",
      transition: "0.1s",
    },
    p: 3,
    minWidth: ["150px", "150px", "250px", "250px", "295px"],
    height: ["40px", "40px", "50px", "50px", "60px"],
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#D4576E",
    fontSize: ["12px", "12px", "16px", "16px", "18px"],
    textTransform: "uppercase",
    fontWeight: "600",
    cursor: "pointer",
  }
  return link ? (
    <Link link={link} sx={style} {...restProps} />
  ) : (
    <div sx={style} {...restProps}>
      {restProps.children}
    </div>
  )
}

export default CTA
