/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"

import Fonts from "./fonts"
import Header from "./header"
import Footer from "./footer"
import "./layout.css"

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <Fonts />
      <div
        sx={{
          minHeight: "100vh",
          width: "100%",
          fontFamily: "body",
          "& *:focus": {
            outline: 0,
          },
        }}
      >
        {children}
      </div>
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
