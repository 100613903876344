/** @jsx jsx */
import { jsx } from "theme-ui"
import { useLocation } from "@reach/router"
import React from "react"
import ReactDOM from "react-dom"

import Text from "./text"
import { usePrevious } from "react-use"
import { motion, AnimatePresence } from "framer-motion"
import { useIsClient } from "../hooks/use-is-client"
import Container from "./container"

const Modal = ({ title, children, onClose, visible, ...restProps }) => {
  const location = useLocation()
  const previousLocation = usePrevious(location)
  const isClient = useIsClient()
  React.useEffect(() => {
    if (visible) {
      window.document.body.parentElement.style.overflowY = "hidden"
    } else {
      window.document.body.parentElement.style.overflowY = "scroll"
    }
    return () => {
      window.document.body.parentElement.style.overflowY = "scroll"
    }
  }, [visible])
  React.useEffect(() => {
    if (previousLocation && previousLocation !== location) onClose()
  }, [location, previousLocation, onClose])
  return (
    isClient &&
    ReactDOM.createPortal(
      <AnimatePresence>
        {visible && (
          <motion.div
            key={1}
            sx={{
              position: "fixed",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              zIndex: 9999,
              background: "rgba(0,0,0,0.5)",
              overflowY: "scroll",
            }}
            exit={{ opacity: 0, overflowY: "hidden" }}
            initial={{ opacity: 0, overflowY: "hidden" }}
            animate={{ opacity: 1, overflowY: "scroll" }}
            transition={{ duration: 0.4 }}
          >
            <motion.div
              sx={{
                minHeight: "100%",
                pt: "20px",
                pb: 6,
                background: "linear-gradient(30deg,#0057b8 30%,#00b5e2)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
              exit={{ top: "200px" }}
              initial={{ top: "200px" }}
              animate={{ top: 0 }}
              transition={{ duration: 0.4 }}
              {...restProps}
            >
              <Container>
                <div
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 3,
                    mx: ["20px", "20px", "20px", 0],
                  }}
                >
                  <Text type="h3">{title || ""}</Text>
                  <Text
                    type="h3"
                    sx={{ color: "white", cursor: "pointer" }}
                    onClick={onClose}
                  >
                    &#10005;
                  </Text>
                </div>

                <div sx={{ width: "100%", height: "100%" }}>{children}</div>
              </Container>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>,
      document.body
    )
  )
}

export default Modal
