/** @jsx jsx */
import { jsx } from "theme-ui"
import Container from "./container"
import { useStaticQuery, graphql } from "gatsby"
import ContentCard from "./content-card"
import Link from "./link"
import CTA from "./cta"
import propaneLogo from "../images/propane-health-logo-white.svg"

const Footer = ({ showContact }) => {
  const data = {
    links: [
      { text: "Learn more about Propane", link: "/propane" },
      { text: "See Healthcare Case Studies", link: "/health" },
    ],
  }

  return (
    <footer
      sx={{
        background: "#171E2F",
        color: "white",
        scrollSnapAlign: "none",
      }}
    >
      <Container sx={{ height: "auto", py: ["20px", "40px", "40px", "20px"] }}>
        <div
          sx={{
            display: "flex",
            height: "100%",
            flexDirection: ["column", "row"],
            alignItems: ["flex-start", "center"],
            justifyContent: "space-between",
          }}
        >
          <div
            sx={{
              display: "flex",
              flexDirection: ["column", "column", "column", "row"],
              justifyContent: [
                "flex-start",
                "space-between",
                "space-between",
                "center",
              ],
              alignItems: ["flex-start", "flex-start", "flex-start", "center"],
            }}
          >
            <img
              src={propaneLogo}
              sx={{
                height: ["40px", "40px", "40px", "40px", "54px"],
                width: "auto",
              }}
            />
            <div
              sx={{
                fontSize: "14px",
                lineHeight: "28px",
                fontWeight: 500,
                ml: [0, "37px", "37px", "37px", "37px", "67px"],
                display: ["none", "none", "none", "block"],
                mt: ["20px", "6px"],
              }}
            >
              © 2020 Propane
            </div>
            <CTA
              sx={{
                display: ["flex", "flex", "flex", "none"],
                width: ["100%", "auto"],
                mt: ["20px", "30px"],
              }}
              link="/"
            >
              Contact us
            </CTA>
          </div>
          <div
            sx={{
              display: "flex",
              flexDirection: ["column", "column", "column", "row"],
              justifyContent: [
                "flex-start",
                "flex-start",
                "flex-start",
                "center",
              ],
              alignItems: ["flex-start", "flex-start", "flex-start", "center"],
            }}
          >
            <CTA
              sx={{
                display: ["none", "none", "none", "flex"],
                width: ["100%", "auto"],
                mt: 0,
              }}
              onClick={showContact}
            >
              Contact us
            </CTA>
            <div
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: ["flex-start", "center"],
                alignItems: ["flex-start", "flex-end"],
                textAlign: ["left", "center"],
                ml: [0, "67px"],
                mt: ["20px", 0],
                py: [0, "14px", "14px", "14px", 0],
              }}
            >
              {data.links.map(link => (
                <Link
                  link={link.link}
                  sx={{
                    color: "#D4576E",
                    textTransform: "uppercase",
                    fontSize: "14px",
                    fontWeight: 600,
                    mb: [0, "14px"],
                    ":last-of-type": {
                      mb: 0,
                    },
                  }}
                >
                  {link.text}
                </Link>
              ))}
              <div
                sx={{
                  fontSize: "14px",
                  lineHeight: "28px",
                  fontWeight: 500,
                  display: ["block", "block", "block", "none"],
                  mt: "14px",
                }}
              >
                © 2020 Propane
              </div>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
