import React from "react"

const breakpointList = ["576px", "768px", "1024px", "1310px", "1600px"]

/**
 * Return a list of min-width media queries based on project's breakpoint list.
 * @param mediaPrefix If set to false, return media query without "@media" prefix.
 * @returns {string[]}
 */
const mediaQueryList = ({ mediaPrefix = true } = {}) =>
  breakpointList.map(breakpoint => {
    const mediaDefinition = `(min-width: ${breakpoint})`

    return mediaPrefix ? `@media ${mediaDefinition}` : mediaDefinition
  })

/**
 * Return boolean indicating if element contains the class name.
 * @param content Content card or Blade
 * @param className Class Name to be checked
 * @returns {boolean}
 */
const hasClass = (content, className) =>
  !!(content.classes && content.classes.includes(className))

/**
 * Return a list of values based on breakpointValue for breakpoints matching
 * responsiveList against project's breakpoint list and a list of values based on
 * defaultValue for those who don't.
 * @param breakpointValue Value to be set if breakpoint is matched.
 * @param defaultValue Value to be set if breakpoint isn't matched.
 * @param responsiveList If it's a populated Array, check for breakpoint matches, otherwise return defaultValue.
 * @returns {(Array|any)} List of values representing breakpointValue/defaultValue or defaultValue.
 */
const getResponsiveValueList = ({
  breakpointValue,
  defaultValue,
  responsiveList,
}) => {
  return responsiveList &&
    Array.isArray(responsiveList) &&
    responsiveList.length
    ? responsiveList.map(breakpointMatch =>
        breakpointMatch ? breakpointValue : defaultValue
      )
    : defaultValue
}

/**
 * Change "\n" occurrences to <br /> tags.
 * @param text
 * @returns {(String|null)} Text with line break tags or null.
 */
const renderLineBreak = text => {
  return text && typeof text === "string"
    ? text.split(/\n|\\n/).reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment]
      }, [])
    : null
}

/**
 * Searchs for an object in the list by it's slug
 * @param list
 * @param slug
 * @returns {(Object|null)}
 */
const findBySlug = ({ list, slug }) => {
  return list.find(data => data.slug === slug)
}

/**
 * Returns a background url list, using desktop image as default
 * if mobile is not provided
 *
 * @param {*} { desktopImage, mobileImage }
 * @returns
 */
const getBackgroundList = ({ desktopImage, mobileImage }) => {
  const desktopBackground = desktopImage
    ? `url(${desktopImage.file.url})`
    : theme => theme.colors.white
  const mobileBackground = mobileImage
    ? `url(${mobileImage.file.url})`
    : desktopBackground

  return [mobileBackground, mobileBackground, desktopBackground]
}

const getRelativeValue = (value, total = 26850) => {
  return (value / total) * 100 + "%"
}

export {
  breakpointList,
  hasClass,
  findBySlug,
  getBackgroundList,
  getResponsiveValueList,
  mediaQueryList,
  renderLineBreak,
  getRelativeValue,
}
